<template>
 <!-- form -->
 <div class="z-10 transform">
  <div class="max-w-auto mx-auto bg-white px-4 py-2">
   <form
    @submit.prevent="groupId ? onEdit($t('successMsg')) : onSubmit($t('successMsg'))"
    class="space-y-8 divide-y divide-gray-200 form_add_new_cti_padding"
    method="POST"
   >
    <div class="pt-4 space-y-6 sm:pt-4 sm:space-y-5">
     <div>
      <div class="inline-flex">
       <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
       >
        <path
         stroke-linecap="round"
         stroke-linejoin="round"
         stroke-width="2"
         d="M16 3h5m0 0v5m0-5l-6 6M5 3a2 2 0 00-2 2v1c0 8.284 6.716 15 15 15h1a2 2 0 002-2v-3.28a1 1 0 00-.684-.948l-4.493-1.498a1 1 0 00-1.21.502l-1.13 2.257a11.042 11.042 0 01-5.516-5.517l2.257-1.128a1 1 0 00.502-1.21L9.228 3.683A1 1 0 008.279 3H5z"
        />
       </svg>
       <h3 class="text-lg pl-2 leading-6 font-medium text-gray-900">
        <span v-if="element === 'extension'">{{ $t("pbx.extensionNewGroupForm") }}</span>
        <span v-if="element === 'queue'">{{ $t("pbx.queueNewGroupForm") }}</span>
       </h3>
      </div>

      <p class="mt-1 max-w-2xl text-sm text-gray-500">
       <span v-if="element === 'extension'">{{ $t("pbx.extensionFormSubtittle") }}</span>
       <span v-if="element === 'queue'">{{ $t("pbx.queueFormSubtittle") }}</span>
      </p>
     </div>
     <div class="space-y-6 sm:space-y-5">
      <div
       class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
      >
       <label
        for="name"
        class="capitalize block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
       >
        {{ $t("callqueueTable.name") }}
       </label>
       <div class="mt-1 sm:mt-0 sm:col-span-2">
        <input
         required
         v-model="this.form.name"
         type="text"
         id="name"
         name="name"
         class="rounded max-w-lg block w-full shadow-sm focus:encom_border_input sm:max-w-xs sm:text-sm border-gray-300"
        />
       </div>
      </div>

      <div
       class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 elements_card_height"
      >
       <label
        for="call_queue_group_id"
        class="capitalize block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
       >
        Elements
       </label>
       <div class="mt-1 sm:mt-0 sm:col-span-2">
        <InputSearchSelector
         v-model:searchElement="searchElement"
         :searchData="element === 'extension' ? extensionsListSliced : queueListSliced"
         :infiniteHandler="infiniteHandler"
         :selected="selectedPbxElementsLabels"
         :urlType="element"
         :addElement="addElement"
         :slicedListIncludes="slicedListIncludes"
         :elementListConcatReduce="element === 'extension' ? extensionsList : queueList"
        />
        <!-- <QuickSearchGroups
                  v-if="extensionsList.length > 0 || queueList.length > 0"
                  :key="this.selectedElementsLength || form.name"
                  :data="element === 'extension' ? extensionsList : queueList"
                  :placeholder="
                    urlType === 'extension'
                      ? $t('dashboards.searchExtension')
                      : $t('dashboards.searchQueue')
                  "
                  @all-selected="$emit('all-selected', $event)"
                  @action="elements = $event"
                  @value="elementsValue = $event"
                  :isMultiple="true"
                  :closeOnSelect="false"
                  :selectAllElements="selectAllElements"
                  :clearAllElements="clearAllElements"
                  :elementToRemove="elementToRemove"
                  maxHeight="150"
                  :selectedElements="this.selectedElements"
                  :selectedElementsLength="selectedElementsLength"
                /> -->
       </div>
       <div
        v-for="element in isEdit ? elementsFromEdit : selectedPbxElements"
        :key="element.value"
        class="relative rounded-lg border border-gray-300 bg-white px-2 py-1 shadow-sm flex items-center hover:border-gray-400"
       >
        <div @click="addElement(element)" class="flex-1 min-w-0">
         <a title="Remove element" class="focus:outline-none cursor-pointer">
          <span class="absolute inset-0" aria-hidden="true" />
          <p class="text-xs font-normal text-gray-900">
           {{ element.label }}
          </p>
         </a>
        </div>
       </div>
      </div>
     </div>
    </div>
    <div class="pt-5">
     <div class="flex justify-end">
      <a
       href="javascript:void(0)"
       @click="this.$emit('closeForm')"
       class="rounded bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
      >
       {{ $t("ticket.cancel") }}
      </a>
      <button
       type="submit"
       class="rounded ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium body__table--header text-white capitalize"
      >
       {{ $t("send") }}
      </button>
     </div>
    </div>
   </form>
  </div>
 </div>
</template>

<script>
import InputSearchSelector from "../InputSearchSelector.vue";
import axios from "axios";
import QuickSearchGroups from "../QuickSearchGroups.vue";
import { mapGetters } from "vuex";

const account = localStorage.getItem("account");

export default {
 name: "CreateNewElementGroup",
 props: [
  "callQueuesGroups",
  "closeFormButton",
  "element",
  "selectedPbxElements",
  "elementsData",
  "name",
  "selectedElementsLength",
  "addElement",
  "selectedPbxElementsFromEdit",
  "isEdit",
  "groupId",
 ],
 components: {
  QuickSearchGroups,
  InputSearchSelector,
 },
 data() {
  return {
   searchElement: "",
   account,
   closeForm: true,
   form: {
    name: "",
   },
   showMessage: false,
   messageToShow: "",
   subMessageToShow: "",
   queueList: [],
   queueListSliced: [],
   extensionsList: [],
   extensionsListSliced: [],
   elements: [],
   elementToRemove: "",
   sliceElements: 500,
  };
 },
 methods: {
  infiniteHandler($state) {
   setTimeout(
    function () {
     if (this.element === "extension") {
      if (this.extensionsListSliced.length == this.extensionsList.length) {
       $state.complete();
      } else {
       this.extensionsListSliced = this.extensionsListSliced.concat(
        this.extensionsList.slice(this.sliceElements - 500, this.sliceElements)
       );
       this.sliceElements = this.sliceElements + 500;
      }
     } else {
      if (this.queueListSliced.length == this.queueList.length) {
       $state.complete();
      } else {
       this.queueListSliced = this.queueListSliced.concat(
        this.queueList.slice(this.sliceElements - 500, this.sliceElements)
       );
       this.sliceElements = this.sliceElements + 500;
      }
     }
     $state.loaded();
    }.bind(this),
    1000
   );
  },
  slicedListIncludes() {
   let flag = false;
   let list = this.element === "extension" ? this.extensionsListSliced : this.queueListSliced;
   for (let index = 0; index < list.length; index++) {
    const element = list[index];
    if (element.label.includes(this.searchElement)) {
     flag = true;
    }
   }
   return flag;
  },
  getPbxMap() {
   const options = {
    method: "GET",
    url: `${this.$cookie.getCookie("API")}/api/v1/pbxMap/${this.hostName}`,
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   };
   axios
    .request(options)
    .then((res) => {
     let obj = {};
     let objQueue = {};
     let extensionsArray = [];
     let keysExtensions = Object.keys(res.data.extensions);
     let keysQueue = Object.keys(res.data.call_queues);
     keysExtensions.forEach((element, index) => {
      obj = {};
      obj["value"] = keysExtensions[index];
      obj["label"] = keysExtensions[index] + " - " + res.data.extensions[element];
      obj["type"] = "extension";
      this.extensionsList.push(obj);
     });
     keysQueue.forEach((element, index) => {
      objQueue = {};
      objQueue["value"] = keysQueue[index];
      objQueue["label"] = keysQueue[index] + " - " + res.data.call_queues[element].name;
      objQueue["members"] = res.data.call_queues[element].members;
      objQueue["type"] = "queue";
      this.queueList.push(objQueue);
     });
    })
    .catch((error) => {
     console.error(error);
    });
  },
  async onSubmit(msg) {
   let dataType = this.element === "extension" ? 0 : 4;
   let data = {};
   console.log(
    "file: CreateNewElementGroup.vue:271 ~ onSubmit ~ this.elementsData:",
    this.selectedPbxElements
   );
   let elementsValue = [];
   if (this.selectedPbxElements.length > 0) {
    for (let index = 0; index < this.selectedPbxElements.length; index++) {
     const element = this.selectedPbxElements[index].value;
     elementsValue.push(element);
    }
    data = {
     name: this.form.name,
     type: dataType,
     resources: elementsValue,
    };

    console.log("file: CreateNewElementGroup.vue:279 ~ onSubmit ~ data:", data);
    try {
     const res = await axios.post(
      `${this.$cookie.getCookie("API")}/api/v1/pbx3cx-hosts/${this.hostName}/resource-groups`,
      data,
      {
       headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
       },
      }
     );
     if (res) {
      this.$emit("activeBanner");
      this.$emit("success", true);
      this.$emit("successMsg", msg);
      this.$emit("closeForm");
     }
    } catch (error) {
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", "Something went wrong");
     this.$emit("closeForm");
     this.errorHandling(error);
    }
   }
  },
  async onEdit(msg) {
   let dataType = this.element === "extension" ? 0 : 4;
   let data = {};
   console.log(
    "file: CreateNewElementGroup.vue:271 ~ onSubmit ~ this.elementsData:",
    this.selectedPbxElements
   );
   let elementsValue = [];
   if (this.selectedPbxElements.length > 0) {
    // for (let index = 0; index < this.selectedPbxElements.length; index++) {
    //  const element = this.selectedPbxElements[index].value;
    //  elementsValue.push(element);
    // }
    data = {
     name: this.form.name,
     type: dataType,
     resources: this.selectedPbxElements,
    };
    console.log("file: CreateNewElementGroup.vue:323 ~ onEdit ~ data:", data);

    try {
     const res = await axios.put(
      `${this.$cookie.getCookie("API")}/api/v1/pbx3cx-hosts/${this.hostName}/resource-groups/${
       this.groupId
      }`,
      data,
      {
       headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
       },
      }
     );
     if (res) {
      this.$emit("activeBanner");
      this.$emit("success", true);
      this.$emit("successMsg", msg);
      this.$emit("closeForm");
     }
    } catch (error) {
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", "Something went wrong");
     this.$emit("closeForm");
     this.errorHandling(error);
    }
   }
  },
  sendMessage(msg, subMsg) {
   this.showMessage = true;
   this.messageToShow = msg;
   this.subMessageToShow = subMsg;
  },
  cleanForm() {
   this.form.name = "";
   this.$emit("clearForm");
   // this.elements = [];
  },
  async getGroupData() {
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/pbx3cx-hosts/${this.hostName}/resource-groups/${
      this.groupId
     }`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );
    if (res) {
     this.form.name = res.data.name;
    }
   } catch (error) {
    this.$emit("activeBanner");
    this.$emit("success", false);
    this.$emit("successMsg", "Something went wrong");
    this.errorHandling(error);
   }
  },
 },
 computed: {
  ...mapGetters(["hostName"]),
  selectedPbxElementsLabels() {
   return this.isEdit
    ? this.selectedPbxElements
    : this.selectedPbxElements.map(({ label }) => label);
  },
  elementsFromEdit() {
   let list = [];
   let selectedElements = this.element === "extension" ? this.extensionsList : this.queueList;
   if (this.isEdit) {
    console.log(
     "file: CreateNewElementGroup.vue:404 ~ list=selectedElements.filter ~ this.selectedPbxElements:",
     this.selectedPbxElements
    );
    list = selectedElements.filter((res) => {
     return (
      this.selectedPbxElements.indexOf(parseInt(res.value)) >= 0 ||
      this.selectedPbxElements.indexOf(res.value) >= 0
     );
    });
   }
   return list;
  },
 },
 mounted() {
  this.getPbxMap();
  this.groupId ? this.getGroupData() : "";
  if (this.name && this.name.length > 0) {
   this.form.name = this.name;
  }
 },
 watch: {
  name: function (val) {
   this.form.name = val;
  },
  selectedElementsLength: function (val) {
   console.log("val", val);
   this.$forceUpdate();
  },
 },
};
</script>

<style>
.elements_card_height {
 min-height: 200px;
}
</style>
